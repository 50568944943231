<template>
    <v-container fluid>
        <v-img
            class="white--text align-top"
            height="250px"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            :src="`/images/farmacia.png`"
        >
            <v-card-title justify-top>
                Existencia de medicamentos
            </v-card-title>
        </v-img>
        <v-toolbar color="primary" dark>
            <v-text-field
                class="mx-15 mb-n5"

                v-model="params.query"
                clearable
                label="Buscar"
            />

            <v-spacer/>
        </v-toolbar>
        <v-sheet class="pa-3 d-flex flex-row">
            <div class="mx-2"><v-icon color="black">mdi-flag</v-icon>Sin existencia</div>
            <div class="mx-2"><v-icon color="red">mdi-flag</v-icon>En mínimos</div>
            <div class="mx-2"><v-icon color="orange">mdi-flag</v-icon>Menos del óptimo</div>
            <div class="mx-2"><v-icon color="green">mdi-flag</v-icon>Suficientes</div>
        </v-sheet>

        <v-data-table
            elevation="2"
            :headers="headers"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"

            item-key="id"
            :loading="loadingMeds"

            :server-items-length='total'
            :options.sync="optionsTable"

            class="elevation-1"

            :items="meds"
            disable-pagination
            disable-filtering

        >
            <template v-slot:[`item.existenciaActual`]="{ item }">
                <v-icon :color="colorExist(item)">
                    mdi-flag
                </v-icon>
            </template>
            <template v-slot:[`item.especificacion`]="{ item }">
                <span v-html='item.especificacion'/>
            </template>
            <template v-slot:[`item.observaciones`]="{ item }">
                <span v-html='item.observaciones'/>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    icon
                    @click="muestraCaducidad(item)"
                >
                    <v-icon>mdi-view-list</v-icon>
                </v-btn>
            </template>

        </v-data-table>
        <v-sheet class="pa-3 d-flex flex-row">
            <div class="mx-2"><v-icon color="black">mdi-flag</v-icon>Sin existencia</div>
            <div class="mx-2"><v-icon color="red">mdi-flag</v-icon>En mínimos</div>
            <div class="mx-2"><v-icon color="orange">mdi-flag</v-icon>Menos del óptimo</div>
            <div class="mx-2"><v-icon color="green">mdi-flag</v-icon>Suficientes</div>
        </v-sheet>

    </v-container>
</template>

<script>
export default {
    data:()=>({

        dialogCaducidad:false,

        params:{},

        headers:[
            {text:'', value:'existenciaActual', width:'5%', align:'center'},
            {text:'Clave', value:'clave', width:'5%',},
            {text:'Concepto', value:'concepto'},
            {text:'Especificacion', value:'especificacion'},
            {text:'Observaciones', value:'observaciones'},
        ],
        meds:[],
        loadingMeds:false,
        esteMed:{},
        caducidades:[],
        caducidadesTotal:0,
        loadingCads:false,

        optionsTable:{},
        total:0,

        busca:{},
    }),

    methods:{

        colorExist(itm){
            let color = 'white'

            if(itm.existenciaActual > itm.optimo){
                color='green'
            }
            if(itm.existenciaActual > itm.minimo && itm.existenciaActual <= itm.optimo){
                color='orange'
            }
            if(itm.existenciaActual >0 && itm.existenciaActual <= itm.minimo){
                color='red'
            }

            if (itm.existenciaActual ==0){
                color='black'
            }
            return color
        },

        async list(){
            this.loadingMeds=true
                this.params.tipoInsumo=1
                this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
                this.params.limit=this.optionsTable.itemsPerPage
            try{
                let eva = await this.$http({
                    url: '/comi/listMedicamentoCb',
                    method:'GET',
                    params: this.params,
                })
                this.loadingMeds=false
                this.meds = eva.data.root
                this.total = eva.data.total
            }catch(err){
                this.loadingMeds=false
                this.$store.dispatch('security/revisaError',err)
            }
        },

    },
    watch:{
        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.list()
            },
            deep: true,
        },
        'params.query'(v){
            if ((v && v.length >=3 && !this.loadingMeds) || !v){
                    this.optionsTable.page = 1,
                    this.list()
            }
        },
    },
}
</script>

<style>

</style>